<template>
  <div>
    <div class="topbasic">
      <div class="tbtitle">基本信息</div>
      <div class="tbcont">
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="账户：">
              <div v-if="!edit_account">
                <span>{{ user.username }}</span>
                <i class="el-icon-edit" @click="edit_account = true"></i>
              </div>
              <div v-else class="changePx">
                <el-input v-model="user.username" />
                <i class="el-icon-check" @click="editSelf()"></i>
              </div>
            </el-form-item>
            <el-form-item label="账户权限：">
              <span>企业</span>
              <!-- <span v-if="user.roles">{{ user.roles.join(",") }}</span> -->
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="联系电话：">
              <div v-if="!edit_tel">
                <span>{{ user.telephone }}</span>
                <i class="el-icon-edit" @click="edit_tel = true"></i>
              </div>
              <div v-else class="changePx">
                <el-input v-model="user.telephone" />
                <i class="el-icon-check" @click="editSelf()"></i>
              </div>
            </el-form-item>
            <el-form-item label="企业名称：">
              <div v-if="!edit_contact">
                <span>{{ user.name }}</span>
                <i class="el-icon-edit" @click="edit_contact = true"></i>
              </div>
              <div v-else class="changePx">
                <el-input v-model="user.name" />
                <i class="el-icon-check" @click="editSelf()"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
           <el-form-item label="重置密码：">
              <div>
                <!-- <span>********</span> -->
                <el-button @click="resetPassword()">重置密码</el-button>
              </div>
            </el-form-item>
            <div style="height:30px"></div>
            <el-form-item>
              <!-- <div v-if="!edit_account">label="账号："
                <span>{{ user.username }}</span>
                <i class="el-icon-edit" @click="edit_account = true"></i>
              </div>
              <div v-else class="changePx">
                <el-input v-model="user.username" />
                <i class="el-icon-check" @click="editSelf()"></i>
              </div> -->
            </el-form-item>

          </el-form>
        </div>
        <div class="account">
          <img v-if="user.photo" class="zizhi" :src="user.photo" />
          <div v-else class="zizhi_no">暂未上传营业执照</div>
        </div>
        <div class="account">
          <img v-if="user.book" style="height: 120px" src="https://cloud.cdmp.tech/088df95e073750ac751d3b927e0c8a6a.png" />
          <div v-else class="zizhi_no">暂未上传能效测评相关证书</div>
        </div>
      </div>
    </div>
    <div class="testtable">
      <div class="tttotle">
        <div class="ttleft">测评人员名单</div>
      </div>
      <div class="ttcontent">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column label="姓名" type="index"> </el-table-column>
          <el-table-column align="center" label="姓名" prop="name">
          </el-table-column>
          <el-table-column
            align="center"
            label="联系方式"
            min-width="180"
            prop="tel"
          ></el-table-column>
          <el-table-column align="center" label="职称" prop="work">
          </el-table-column>
          <el-table-column align="center" label="职务" prop="job">
          </el-table-column>
          <el-table-column align="center" label="证书" prop="file">
            <template slot-scope="{row}">
              <span v-if="row.file">证书已上传</span>
              <span v-else>证书未上传</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" style="color: red">
            <template slot-scope="{ row }">
              <div @click="delStaff(row.id)">
                <i class="el-icon-delete"></i>删除
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-count="totalPage"
            :page-size="limit"
            :total="totalCount"
            background
            layout=" prev, pager, next, total, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  staffListApi,
  staffDelApi,
  editSelfApi,
  userInfoApi,
} from "../../../../api/project";
import {resetPasswordApi} from "../../../../api/user";

export default {
  data() {
    return {
      form: {},
      tableData: [],
      labelPosition: "right",
      formLabelAlign: {},

      user: this.$store.state.user,

      page: 1,
      totalPage: 0,
      limit: 10,
      totalCount: 0,

      fileList: [],
      fileList2: [],
      edit_tel: false,
      edit_contact: false,
      edit_account: false,
      edit_pwd: false,

      newPassword: '',
      oldPassword: '',


      token: '',
      userId: '',
    };
  },

  mounted() {
    this.token = this.$route.query.userToken;
    this.userId = this.$route.query.id;
    this.getUserInfo();
    this.getStaffList();
    console.log(this.$store.state.user);
  },
  methods: {
    //增加员工文件上传成功回调
    fileUploadSuccess2(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList2 = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList2);
    },
    //增加员工文件上传成功回调
    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
    },
    //获得主账号信息
    getUserInfo() {
      let params = {
        token: this.token,
      };
      let that = this;
      userInfoApi(params).then((res) => {
        console.log(res);
        that.user = res.data;
        if (that.user.photo && that.user.photo.length > 0) {
          this.fileList = that.user.photo;
        }
        if (that.user.book && that.user.book.length > 0) {
          this.fileList2 = that.user.book;
        }
      });
    },

    //重置密码
    resetPassword(){
      let params = {
        id: this.userId,
        token: this.token,
      }
      resetPasswordApi(params).then(() => {
        this.$message.success("密码重置成功")
      })
    },


    //编辑信息
    editSelf() {
      this.edit_tel = false;
      this.edit_contact = false;
      this.edit_account = false;
      let params = {
        name: this.user.name,
        telephone: this.user.telephone,
        token: this.token,
        id: this.userId,
      };
      if (this.edit_pwd) {
        this.edit_pwd = false;
        params.oldpassword = this.oldPassword
        params.password = this.newPassword;
      }
      if (this.fileList && this.fileList.length > 0) {
        params.photo = this.fileList[0].url;
      }
      if (this.fileList2 && this.fileList2.length > 0) {
        params.book = this.fileList2[0].url;
      }
      let that = this;
      editSelfApi(params).then((res) => {
        console.log(res);
        that.$message.success("修改成功");
      });
    },

    //删除员工
    delStaff(id) {
      let params = {
        token: this.token,
        id: id,
      };
      let that = this;
      staffDelApi(params).then((res) => {
        console.log(res);
        // that.$message.success(res)
        that.getStaffList();
      });
    },

    //获得员工列表
    getStaffList() {
      let params = {
        page: this.page,
        limit: this.limit,
        token: this.token,
      };
      let that = this;
      staffListApi(params).then((res) => {
        console.log(res);
        that.tableData = res.data;
        that.page = res.page;
        that.pageCount = res.pageCount;
        that.totalCount = res.totalCount;
      });
    },

    handleSizeChange(limit) {
      this.limit = limit;
      this.getStaffList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getStaffList();
    },
  },
};
</script>
<style scoped>
/deep/.el-form-item__content > span {
  font-size: 18px;
  font-weight: normal;
  color: #333333;
}
/deep/.el-form-item__label {
  font-size: 18px;
  font-weight: normal;
  color: #999999;
}
/deep/.el-upload-dragger {
  border: 1px dashed #3086fb;
}
/deep/.el-upload-dragger .el-icon-upload {
  margin: 18px 0 16px;
  color: #3086fb;
}
/deep/.el-upload-dragger .el-upload__text {
  color: #3086fb;
}
/deep/.el-upload-dragger {
  width: 261px;
  height: 140px;
}
/deep/.el-icon-edit {
  color: #3086fb;
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.changePx {
  display: flex;
  flex-direction: row;
  .el-icon-check{
    margin-top: 10px;
    margin-left: 10px;
  }
}
.topbasic {
  margin: 30px 0;
  padding: 20px 50px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
  .tbtitle {
    margin: 22px 50px;
    font-size: 18px;
    color: #333333;
  }
  .tbcont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .zizhi {
      height: 120px;
      width: 200px;
    }
    .zizhi_no {
      border: 1px solid #4aa1fe;
      color: #4aa1fe;
      border-radius: 10px;
      height: 120px;
      text-align: center;
      width: 200px;
      line-height: 120px;
    }
  }
}
.testtable {
  margin-bottom: 20px;

  .tttotle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    .ttleft {
      font-size: 18px;
      font-weight: normal;
      color: #333333;
      padding-left: 40px;
    }
  }
  .ttcontent {
    background-color: #ffffff;
  }
  .pageTotal {
    margin-top: 37px;
    display: flex;
    justify-content: center;
  }
}
</style>
